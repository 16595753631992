import React, { useEffect } from "react";
import Box from "@mui/material/Box";

import Header from "./Header";

const drawerWidth = 250;

export default function PrimaryLayout({ children, ...props }) {
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: { xs: 3, sm: 3, md: 5, lg: 14.5, xl: 14.5 },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "100vh",
          padding: "0rem 115px",
        }}
      >
        <Header />
        {children}
      </Box>
    </Box>
  );
}
