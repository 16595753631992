import { API } from "src/apis/config";
import { getAddress } from "ethers/lib/utils";

const fetchInviteLinkData = inviteId => {
	return API.post(`invite/data`, { inviteId });
};

const submitFormData = data => {
	return API.post("invite/submit", data);
};

const checkContributor = data => {
	return API.post("invite/checkContributor", {
		walletAddress: getAddress(data.walletAddress),
        inviteId: data.inviteId,
	});
};

export default {
	fetchInviteLinkData,
	submitFormData,
	checkContributor
};
