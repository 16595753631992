import React from "react";
import Head from "next/head";
import { AppProps } from "next/app";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider, EmotionCache } from "@emotion/react";

import theme from "styles/theme";
import createEmotionCache from "styles/createEmotionCache";
import RootHoc from "src/components/Root/RootHoc";
import { BaseContextProvider } from "src/contexts/baseContext";
import { InviteLinkProvider } from "src/contexts/inviteLinkContext";
import { SnackbarProvider } from "notistack";
import { Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import "../styles/globals.css";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const notistackRef: any = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  return (
    <CacheProvider value={emotionCache}>
      <InviteLinkProvider>
        <BaseContextProvider>
          <Head>
            <title>Parcel Invite Link</title>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
          </Head>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              ref={notistackRef}
              action={key => (
                <Button color="inherit" onClick={onClickDismiss(key)}>
                  <CancelIcon />
                </Button>
              )}
            >
              <CssBaseline />
              <RootHoc Component={Component} {...pageProps} />
            </SnackbarProvider>
          </ThemeProvider>
        </BaseContextProvider>
      </InviteLinkProvider>
    </CacheProvider>
  );
}
