import React, { useEffect, useContext, useState } from "react";
import { useRouter } from "next/router";

import Home from "pages/index";
import PrimaryLayout from "src/layouts/PrimaryLayout";
import { useBaseContext } from "src/contexts/baseContext";
import { InviteLinkContext } from "src/contexts/inviteLinkContext";
import InviteLinkAPIS from "src/apis/inviteLink";
import InvalidModal from "src/components/InvalidModal";

function RootHoc({ Component, pageProps }) {
  const router = useRouter();
  const { web3 } = useBaseContext();
  const [loading, setLoading] = useState(true);
  const { inviteLinkData, setInviteLinkData, setInviteId, error, setError, setNetworkId }: any =
    useContext(InviteLinkContext);

  const fetchData = async id => {
    try {
      const res = await InviteLinkAPIS.fetchInviteLinkData(id);
      setInviteLinkData(res?.data);
      setNetworkId(res?.data.networkId || 1);
      setInviteId(id);
    } catch (error) {
      setError(error?.response?.data?.message || "Invalid link");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const id = router?.asPath?.slice(1);
    fetchData(id);
  }, []);

  useEffect(() => {
    if (inviteLinkData && !inviteLinkData.isExpired) router.push("/invite");
  }, [inviteLinkData]);

  const getErrorMsg = () => {
    if (inviteLinkData?.isExpired) return "The Contributor Invite Link you are looking for is either Invalid, Expired or Used.";
    if (inviteLinkData?.status === "inactive") return "Link is inactive";
    if(inviteLinkData?.isExhausted) return "Link has reached it's limit"
    if (error) return error;

    return null;
  };
  if (loading) {
    return null;
  }
  const errorMsg = getErrorMsg();

  if (errorMsg) {
    return (
      <PrimaryLayout>
        <InvalidModal errorMsg={errorMsg} />
      </PrimaryLayout>
    );
  }

  if (web3.account) {
    return (
      <PrimaryLayout>
        <Component {...pageProps} />
      </PrimaryLayout>
    );
  }
  return (
    <PrimaryLayout>
      <Home {...pageProps} />
    </PrimaryLayout>
  );
}

export default RootHoc;
