import styled from "@emotion/styled";
import theme from "styles/theme";
import Box from "@mui/material/Box";
import { Link } from "@mui/material";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 14px 0px;
  border-bottom: 1px solid #e2e5e9;

  .role-box {
    display: flex;
    align-items: center;
  }
`;

export const HeaderActions = styled(Box)`
  align-self: center;
  display: flex;
  height: 100%;
  padding: 5px 5px 5px 12px;
  align-items: center;
  background: #e3e9f9;
  border-radius: 28px;
  color: ${theme.palette.title.main};
  cursor: pointer;
  .account-address {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
  }
`;
export const HeaderText = styled(Box)`
  align-self: center;
  display: flex;
  height: 100%;
  padding: 12px;
  align-items: center;
  background: #ffffff;
  border-radius: 28px;
  border: 1px solid black;
  cursor: pointer;
  color: ${theme.palette.title.main};
  cursor: pointer;
  .account-address {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
  }
`;

export const ImgeWrapper = styled(Box)`
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

export const HeaderActionBtn = styled(Box)`
  align-self: center;
  background: #ffffff;
  border-radius: 50%;
  padding: 8px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  cursor: pointer;

  .icon {
    font-size: 22px;
  }
`;

export const StyledPopOver = styled(Box)`
  font-size: 14px;
  padding: 10px;
  margin-top: 5px;
  font-weight: 500;
  background: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background: ${theme.background_color.lightprimary};
  }
`;

export const StyledLink = styled(Link)`
  font-size: 14px;
  padding: 10px;
  margin-top: 5px;
  font-weight: 500;
  background: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  &:hover {
    background: ${theme.background_color.lightprimary};
  }
`;

export const HeaderSWitch = styled(Box)`
  align-self: center;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  padding: 8px 12px;
  border-radius: 32px;
  font-weight: 500;
  background: #ffffff;
  border: 1px solid ${({ theme }: any) => theme.palette.border_color.main};
  cursor: pointer;
  align-items: center;

  .icon {
    font-size: 16px;
  }
`;
