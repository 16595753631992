import React, { useCallback, useContext, useRef, useState } from "react";
import { useRouter } from "next/router";
import {
  HeaderWrapper,
  HeaderActions,
  ImgeWrapper,
  HeaderActionBtn,
  StyledPopOver,
  StyledLink,
} from "./styles";
import { Typography, Grow, ClickAwayListener } from "@mui/material";
import Image from "next/image";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";

import { useBaseContext } from "src/contexts/baseContext";
import CopyOnClick from "src/components/CopyOnClick";
import { selectWalletIcon } from "src/components/Icons";
import ParcelLogo from "assets/parcel_logo.svg";
import { minifyAddress } from "src/helpers/web3Utils";
import { InviteLinkContext } from "src/contexts/inviteLinkContext";

function Header() {
  const router = useRouter();
  const disconnectRef = useRef(null);
  const [open, setOpen] = React.useState(false);

  const { web3 } = useBaseContext();
  const { onboard, account, connector, active, handleResetBaseContext } = web3;
  const { inviteId, handleResetInviteLink }: any = useContext(InviteLinkContext);

  const clickAway = () => {
    setOpen(false);
  };

  const handleDisconnect = useCallback(() => {
    onboard.walletReset();
    handleResetInviteLink();
    handleResetBaseContext();
    router.push(`/${inviteId}`);
  }, [onboard, active]);

  const renderAddressTab = () => {
    if (!web3.account) return null;
    return (
      <HeaderActions>
        <CopyOnClick
          style={{ dispalay: "inline" }}
          value={account}
          text="Click to Copy Wallet Address"
        >
          <Typography className="account-address">{minifyAddress(account)}</Typography>
        </CopyOnClick>
        {selectWalletIcon[connector.name] && (
          <ImgeWrapper ml={1.5}>
            <Image
              src={selectWalletIcon[connector.name]}
              alt="wallet icon"
              height="16px"
              width="16px"
            />
          </ImgeWrapper>
        )}
      </HeaderActions>
    );
  };

  const renderMoreInfoTab = () => {
    if (!web3.account) return null;
    return (
      <Box ref={disconnectRef} onClick={() => setOpen(prev => !prev)}>
        <HeaderActionBtn ml={1}>
          <MoreHorizIcon className="icon" />
        </HeaderActionBtn>
        <Popper
          anchorEl={disconnectRef.current}
          // className={classes.popper}
          open={open}
          placement="bottom"
          // popperOptions={{ positionFixed: true }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <>
                <ClickAwayListener mouseEvent="onClick" onClickAway={clickAway} touchEvent={false}>
                  <StyledPopOver onClick={handleDisconnect} color="title.main">
                    Disconnect
                  </StyledPopOver>
                </ClickAwayListener>
                <ClickAwayListener mouseEvent="onClick" onClickAway={clickAway} touchEvent={false}>
                  <StyledLink href={`https://etherscan.io/address/${account}`} target="_blank">
                    View On Etherscan
                  </StyledLink>
                </ClickAwayListener>
              </>
            </Grow>
          )}
        </Popper>
      </Box>
    );
  };
  return (
    <HeaderWrapper>
      <div style={{ marginRight: "auto", alignSelf: "center" }}>
        <img src={ParcelLogo.src} style={{ height: "30px", verticalAlign: "middle" }} />
      </div>
      {renderAddressTab()}
      {renderMoreInfoTab()}
    </HeaderWrapper>
  );
}

export default Header;
